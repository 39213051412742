<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="toAssingDashboard">
      <div class="row">
        <div class="col-12 pb-0">
          <h6>
            <v-icon dense class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
            Información de asignación
          </h6>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            name="level_dashboard"
            rules="required"
          >
            <v-select
              v-model="modelUserAbasto.levelDashboard"
              :items="listNivelDashboard"
              :error-messages="errors"
              required
              :label="$t('label.level_dashboard')"
              data-vv-name="select"
            ></v-select>
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider
            v-slot="{ errors }"
            name="dasboard_distribution_board_main"
            rules="required"
          >
            <v-select
              v-model="modelUserAbasto.distributionBoardId"
              :items="typeDashboard"
              :error-messages="errors"
              required
              :label="$t('label.dasboard_distribution_board_main')"
              data-vv-name="select"
            ></v-select>
          </validation-provider>
        </div>
        <div class="col-md-12" v-if="modelUserAbasto.levelDashboard == 2">
          <validation-provider
            v-slot="{ errors }"
            name="dasboard_distribution_board_main"
            rules="required"
          >
            <v-select
              v-model="modelUserAbasto.jurisdictionId"
              :items="jurisdictions"
              :error-messages="errors"
              required
              :label="$t('label.jurisdiction')"
              data-vv-name="select"
            ></v-select>
          </validation-provider>
        </div>
        <div class="col-md-12" v-if="modelUserAbasto.levelDashboard == 3">
          <div class="pb-5">
            <h6>
              <v-icon dense class="pb-1" v-text="'mdi-numeric-2-box-outline'" />
              Asignación de sucursales
            </h6>
          </div>
          <BranchOfficeCommon
            ref="get_listBranchOfficeUser"
            :userListBranchOffice="modelUserAbasto.userListBranchOfficeIds"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-right">
          <button
            class="btn btn-outline-success"
            type="submit"
            :disabled="invalid"
          >
            <i class="feather icon-save mr-1"></i>
            {{ modelUserAbasto.idDashboard ? "Modificar" : "Asignar" }}
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { JurisdictionRequest } from "@/core/request";
import BranchOfficeCommon from "@/common/branchOffice/BranchOfficeCommon";
import { SwalConfirm } from "@/core/SwalAlert";

/* Vuex */
import { mapState } from "vuex";
export default {
  props: {
    modelUserAbasto: Object,
    changeAction: Function,
    alert: Function,
    /* Servicies */
    setUserDashboardAbasto: Function,
  },
  data() {
    return {
      listNivelDashboard: [
        {
          text: "NIVEL ABASTO",
          value: 1,
        },
        {
          text: "NIVEL JURISDICCIÓN",
          value: 2,
        },
        {
          text: "NIVEL SUCURSAL",
          value: 3,
        },
      ],
      jurisdictions: [],
    };
  },
  computed: {
    // Extración del state
    ...mapState("global", {
      typeDashboard: "typeDashboard",
    }),
  },
  methods: {
    async toAssingDashboard() {
      let dashboardModel = {
        user: {},
      };
      let message = "";
      switch (this.modelUserAbasto.levelDashboard) {
        case 1:
          message = this.$t("configuration.message_abasto", {
            name: this.modelUserAbasto.userName,
          });
          break;
        case 2:
          dashboardModel.jurisdictionId = this.modelUserAbasto.jurisdictionId;
          message = this.$t("configuration.message_jurisdiction", {
            name: this.modelUserAbasto.userName,
          });
          break;
        case 3:
          dashboardModel.branchOffices = await this.$refs.get_listBranchOfficeUser.getListBranchOfficeUser();
          if (dashboardModel.branchOffices == undefined) return;
          if (this.modelUserAbasto.userListBranchOfficeIds == undefined) {
            if (dashboardModel.branchOffices.branchOfficesAdd.length <= 0) {
              this.alert("error", "Message_Branch_Office_0001");
              return;
            }
            message = this.$t("configuration.message_branch", {
              name: this.modelUserAbasto.userName,
            });
            break;
          } else if (
            dashboardModel.branchOffices.branchOfficesAdd.length <= 0 &&
            this.modelUserAbasto.userListBranchOfficeIds.length ==
              dashboardModel.branchOffices.branchOfficesDelete.length
          ) {
            this.alert("error", "Message_Branch_Office_0001");
            return;
          }
          message = this.$t("configuration.message_branch", {
            name: this.modelUserAbasto.userName,
          });
          break;
      }
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        title: "Tablero de Control Abasto",
        html: message,
      });
      if (!isConfirmed) {
        return;
      }
      dashboardModel.dashboardTypeId = 1;
      dashboardModel.user.idUser = this.modelUserAbasto.idUser;
      dashboardModel.distributionBoardId = this.modelUserAbasto.distributionBoardId;
      dashboardModel.levelDashboard = this.modelUserAbasto.levelDashboard;
      this.setUserDashboardAbasto(
        dashboardModel,
        this.modelUserAbasto.idDashboard
      );
    },
    listAllJurisdictions() {
      JurisdictionRequest.listAll()
        .then((res) => {
          let listJurisdictions = [];
          let { data } = res.data.data;
          if (data.objects) {
            listJurisdictions = data.objects.map((obj) => {
              return {
                value: obj.idJurisdiction,
                text: obj.jurisdictionname,
              };
            });
          }
          this.jurisdictions = listJurisdictions.filter(
            (jurisdiction) => jurisdiction.value != 1
          );
        })
        .catch((error) => {
          console.log(error);
          this.alert("internal");
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
  created() {
    this.listAllJurisdictions();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BranchOfficeCommon,
  },
};
</script>
