var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"containerbar"}},[_c('div',{staticClass:"rightbar"},[_c('div',{staticClass:"breadcrumbbar"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-8 col-lg-8 col-xs-12 col-sm-12"},[_c('h4',{staticClass:"page-title"},[_vm._v("Configuración")]),_c('div',{staticClass:"breadcrumb-list"},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_c('li',{staticClass:"breadcrumb-item"},[_c('a',{on:{"click":function($event){return _vm.changeAction(1)}}},[_vm._v("Configuración")])]),_c('li',{staticClass:"breadcrumb-item"},[_vm._v(_vm._s(_vm.title))])])])]),_c('div',{staticClass:"col-md-4 col-lg-4 col-xs-12 col-sm-12 content-button"},[(_vm.showAction(2) || _vm.showAction(3))?_c('button',{staticClass:"btn btn-outline-success",on:{"click":function($event){return _vm.changeAction(1)}}},[_c('i',{staticClass:"ti-arrow-left mr-2"}),_vm._v(" Regresar ")]):_vm._e()])])]),_c('div',{staticClass:"contentbar"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-xl-12"},[_c('div',{staticClass:"card"},[_c('AlertMessageComponent',{ref:"alertMessageComponent"}),_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('h5',{staticClass:"card-title"},[_c('i',{staticClass:"feather icon-layout mr-1",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"col-md-4 col-lg-3"},[_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(
                      _vm.showAction(1) &&
                        _vm.withPermissionName(
                          _vm.permissions.CONFIGURATION_DASHBOARD_ABASTO
                        ) &&
                        _vm.withPermissionName(
                          _vm.permissions.CONFIGURATION_DASHBOARD_STATISTIC
                        )
                    ),expression:"\n                      showAction(1) &&\n                        withPermissionName(\n                          permissions.CONFIGURATION_DASHBOARD_ABASTO\n                        ) &&\n                        withPermissionName(\n                          permissions.CONFIGURATION_DASHBOARD_STATISTIC\n                        )\n                    "}],staticClass:"br",attrs:{"items":_vm.listTypeDashboard,"dense":"","outlined":"","append-icon":"feather icon-layout","label":"Tablero de Control","data-vv-name":"select"},model:{value:(_vm.typeDashboard),callback:function ($$v) {_vm.typeDashboard=$$v},expression:"typeDashboard"}})],1)])]),(
                _vm.withPermissionName(_vm.permissions.CONFIGURATION_DASHBOARD_ABASTO)
              )?_c('div',[_c('TableAbastoConfigComponents',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAction(1) && _vm.typeDashboard == 1),expression:"showAction(1) && typeDashboard == 1"}],attrs:{"headers":_vm.headersAbasto,"isStatus":_vm.withPermissionName(_vm.permissions.COF_EDIT_ABASTO_STATUS_01),"isUpdate":_vm.withPermissionName(_vm.permissions.COF_EDIT_ABASTO_LEVEL_02),"isAssign":_vm.withPermissionName(_vm.permissions.COF_EDIT_ABASTO_LEVEL_02),"toAssingDashboard":_vm.toAssingDashboardAbasto,"updateItem":_vm.updateItemAbasto,"confirmChangeStatus":_vm.confirmChangeStatusAbasto,"pagesAtributes":_vm.pagesAtributes,"methodPage":_vm.getAllUserDashboardAbasto,"modelTable":_vm.listUsersAbasto}})],1):_vm._e(),(
                _vm.typeDashboard == 2 &&
                  _vm.withPermissionName(
                    _vm.permissions.CONFIGURATION_DASHBOARD_STATISTIC
                  )
              )?_c('TableStatisticConfigComponents',{attrs:{"dataList":_vm.listUsersStatistic,"headers":_vm.headersStatistics,"isStatus":_vm.withPermissionName(_vm.permissions.COF_EDIT_STATISTIC_STATUS_01),"confirmchangeStatus":_vm.confirmChangeStatusStatistic,"pagesAtributes":_vm.pagesAtributes,"methodPage":_vm.getAllUserDashboardStatistics,"modelTable":_vm.listUsersStatistic}}):_vm._e(),(_vm.showAction(2))?_c('div',{staticClass:"card-body"},[_c('FormAbastoConfigComponents',{attrs:{"changeAction":_vm.changeAction,"modelUserAbasto":_vm.modelUserAbasto,"alert":_vm.alert,"setUserDashboardAbasto":_vm.setUserDashboardAbasto}})],1):_vm._e()],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/dashboard"}},[_vm._v("Inicio")])])}]

export { render, staticRenderFns }