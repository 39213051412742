<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-8 col-lg-8 col-xs-12 col-sm-12">
            <h4 class="page-title">Configuración</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/dashboard">Inicio</a></li>
                <li class="breadcrumb-item">
                  <a @click="changeAction(1)">Configuración</a>
                </li>
                <li class="breadcrumb-item">{{ title }}</li>
              </ol>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> Regresar
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row">
                  <div class="col">
                    <h5 class="card-title">
                      <i
                        class="feather icon-layout mr-1"
                        aria-hidden="true"
                      ></i>
                      {{ title }}
                    </h5>
                  </div>
                  <div class="col-md-4 col-lg-3">
                    <v-select
                      v-show="
                        showAction(1) &&
                          withPermissionName(
                            permissions.CONFIGURATION_DASHBOARD_ABASTO
                          ) &&
                          withPermissionName(
                            permissions.CONFIGURATION_DASHBOARD_STATISTIC
                          )
                      "
                      v-model="typeDashboard"
                      :items="listTypeDashboard"
                      dense
                      outlined
                      class="br"
                      append-icon="feather icon-layout"
                      label="Tablero de Control"
                      data-vv-name="select"
                    ></v-select>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  withPermissionName(permissions.CONFIGURATION_DASHBOARD_ABASTO)
                "
              >
                <TableAbastoConfigComponents
                  v-show="showAction(1) && typeDashboard == 1"
                  :headers="headersAbasto"
                  :isStatus="
                    withPermissionName(permissions.COF_EDIT_ABASTO_STATUS_01)
                  "
                  :isUpdate="
                    withPermissionName(permissions.COF_EDIT_ABASTO_LEVEL_02)
                  "
                  :isAssign="
                    withPermissionName(permissions.COF_EDIT_ABASTO_LEVEL_02)
                  "
                  :toAssingDashboard="toAssingDashboardAbasto"
                  :updateItem="updateItemAbasto"
                  :confirmChangeStatus="confirmChangeStatusAbasto"
                  :pagesAtributes="pagesAtributes"
                  :methodPage="getAllUserDashboardAbasto"
                  :modelTable="listUsersAbasto"
                />
              </div>
              <TableStatisticConfigComponents
                v-if="
                  typeDashboard == 2 &&
                    withPermissionName(
                      permissions.CONFIGURATION_DASHBOARD_STATISTIC
                    )
                "
                :dataList="listUsersStatistic"
                :headers="headersStatistics"
                :isStatus="
                  withPermissionName(permissions.COF_EDIT_STATISTIC_STATUS_01)
                "
                :confirmchangeStatus="confirmChangeStatusStatistic"
                :pagesAtributes="pagesAtributes"
                :methodPage="getAllUserDashboardStatistics"
                :modelTable="listUsersStatistic"
              />
              <div v-if="showAction(2)" class="card-body">
                <FormAbastoConfigComponents
                  :changeAction="changeAction"
                  :modelUserAbasto="modelUserAbasto"
                  :alert="alert"
                  :setUserDashboardAbasto="setUserDashboardAbasto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TableAbastoConfigComponents from "@/components/configuration/configDashboard/TableAbastoConfigComponents";
import TableStatisticConfigComponents from "@/components/configuration/configDashboard/TableStatisticConfigComponents";
import FormAbastoConfigComponents from "@/components/configuration/configDashboard/FormAbastoConfigComponents";
import {
  CONFIG_USERS_DASBOARD_ABASTO,
  CONFIG_USERS_DASBOARD_STATISTIC,
} from "@/core/DataTableHeadersLocale";
import { SwalConfirm } from "@/core/SwalAlert";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";

import { ConfigurationRequest } from "@/core/request";
import { UtilFront, PermissionConstants } from "@/core";

export default {
  data() {
    return {
      action: 1,
      /** * @member {Object} listUsersAbasto Objecto para usuarios de abasto, y atributos para realizar la paginación */
      listUsersAbasto: {
        content: [] /** * @member {Object} content Lista de usuarios de abasto. */,
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      /** * @member {Object} listUsersStatistic Objecto para usuarios de estadistica, y atributos para realizar la paginación */
      listUsersStatistic: {
        content: [] /** * @member {Object} content Lista de usuarios de estadistica. */,
        pageable: {},
        totalPages: 0,
        totalElements: 0,
      },
      /** * @member {Object} headersAbasto Cabeceras para la tabla de usuario de abasto */
      headersAbasto: CONFIG_USERS_DASBOARD_ABASTO(),
      /** * @member {Object} headersStatistics Cabeceras para la tabla de usuario de estadistica */
      headersStatistics: CONFIG_USERS_DASBOARD_STATISTIC(),
      typeDashboard: 1,
      listTypeDashboard: [
        {
          text: "Abasto",
          value: 1,
        },
        {
          text: "Estadistica",
          value: 2,
        },
      ],
      /** * @member {Object} modelUserAbasto Informacion necesario para el despliegue de formulario */
      modelUserAbasto: {
        idUser: null,
        userEmail: null,
        userName: null,
        levelDashboard: null,
        userLevelName: null,
        jurisdictionId: null,
        jurisdictionName: null,
        userNoBranchOffice: null,
        distributionBoardId: null,
        distributionBoardName: null,
        availableDashboard: null,
        userListBranchOfficeIds: null,
      },
      pagesAtributes: {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
        sortBy: "",
        sortDesc: false,
      },
      loading: false,
      permissions: PermissionConstants,
    };
  },
  computed: {
    title() {
      return this.action == 1
        ? "Configuración de tablero de control"
        : this.action == 2
        ? "Asignación de Tablero de Control Abasto"
        : "";
    },
  },
  methods: {
    withPermissionName(namePermission) {
      return UtilFront.withPermissionName(namePermission);
    },
    // Función de alert global para todas las vistas
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      action == 1 && this.clear();
      this.action = action;
    },
    /**
     * Limpia el objeto modelUserAbasto.
     * @method
     */
    clear() {
      this.modelUserAbasto = {
        idUser: null,
        idDashboard: undefined,
        userEmail: null,
        userName: null,
        levelDashboard: null,
        userLevelName: null,
        jurisdictionId: null,
        jurisdictionName: null,
        userNoBranchOffice: null,
        distributionBoardId: null,
        distributionBoardName: null,
        availableDashboard: null,
        userListBranchOfficeIds: null,
      };
    },
    /**
     * Una función que se ejecuta cuando se marca o desmarca una casilla de verificación.
     * @method
     * @param {Object} item la informacion del usuario seleccionado
     */
    async confirmChangeStatusStatistic(item) {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        title: "Tablero de Control Estadistica",
        html: item.availableDashboard
          ? this.$t("configuration.available_statistic", {
              name: item.userName,
              email: item.userEmail,
            })
          : this.$t("configuration.unavailable_statistic", {
              name: item.userName,
              email: item.userEmail,
            }),
      });
      if (!isConfirmed) {
        item.availableDashboard = !item.availableDashboard;
        return;
      }
      this.loading = UtilFront.getLoadding();
      let data = {
        user: {
          idUser: item.idUser,
        },
        dashboardTypeId: 2,
        availableDashboard: item.availableDashboard ? 1 : 0,
      };
      await ConfigurationRequest.updateDasboardStatusUser(data)
        .then((res) => {
          let { data } = res;
          if (!data.undefined) {
            this.alert("success", "Update_Success_0001");
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
          item.availableDashboard = !item.availableDashboard;
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Esta función se llama cuando el usuario hace clic en un botón de la tabla, y establece el valor de la variable
     * action a 2, y establece los valores de las variables idUser y userName en el objeto
     * modeloUserAbasto a los valores de las variables idUser y userName en el objeto item.
     * objeto.
     * @method
     * @param {Array} - {idUser: 1, userName: user1}
     */
    toAssingDashboardAbasto(item) {
      this.action = 2;
      this.modelUserAbasto.idUser = item.idUser;
      this.modelUserAbasto.userName = item.userName;
    },
    /**
     * Función que cambia el estado de un usuario en la tabla, y se
     * llama cuando el usuario hace clic en una casilla de verificación, para desabilitar o habilitar
     * el tablero de control de abasto
     * </code>
     * @method
     * @param {Array} El usuario enviado para cambiar el estado
     */
    async confirmChangeStatusAbasto(item) {
      const { isConfirmed } = await SwalConfirm.fire({
        icon: "warning",
        title: "Tablero de Control Abasto",
        html: item.availableDashboard
          ? this.$t("configuration.available_abasto", {
              name: item.userName,
              email: item.userEmail,
            })
          : this.$t("configuration.unavailable_abasto", {
              name: item.userName,
              email: item.userEmail,
            }),
      });
      if (!isConfirmed) {
        item.availableDashboard = !item.availableDashboard;
        return;
      }
      this.loading = UtilFront.getLoadding();
      let data = {
        user: {
          idUser: item.idUser,
        },
        dashboardTypeId: 1,
        availableDashboard: item.availableDashboard,
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: UtilFront.getDataUser().mainBranch,
      };
      await ConfigurationRequest.updateDasboardStatusUser(data)
        .then((res) => {
          let { data } = res;
          if (!data.undefined) {
            this.alert("success", "Update_Success_0001");
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
          item.availableDashboard = !item.availableDashboard;
        })
        .finally(() => {
          this.loading.hide();
        });
    },

    /**
     * Esta función se llama cuando el usuario hace clic en el botón "Editar" de la tabla.
     * Toma el elemento sobre el que se ha hecho clic y lo copia en el objeto modelUserAbasto.
     * Y establece la variable action en 2 para mostrar el formulario
     * @method
     * @param {Object} El usuario seleccionado
     */
    async updateItemAbasto(item) {
      let data = {
        user: {
          idUser: item.idUser,
        },
      };
      this.loading = UtilFront.getLoadding();
      await ConfigurationRequest.getBranchOfficeUser(data)
        .then((res) => {
          let { data } = res;
          if (data) {
            item.userListBranchOfficeIds = data;
            this.clear();
            this.modelUserAbasto = { ...item };
            this.action = 2;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Es una función que hace una petición al servidor y devuelve una promesa.
     * @method
     * @param {Object}
     * @param {Boolean} - boolean
     */
    async setUserDashboardAbasto(data, add) {
      this.loading = UtilFront.getLoadding();
      let service = add
        ? ConfigurationRequest.updateUserDashboardAbasto(data)
        : ConfigurationRequest.setUserDashboardAbasto(data);
      service
        .then((res) => {
          let { data } = res;
          if (!data.undefined) {
            add
              ? this.alert("success", "Success_Configuration_0002")
              : this.alert("success", "Success_Configuration_0001");
            this.loading.hide();
            this.changeAction(1);
            this.getAllUserDashboardAbasto(this.pagesAtributes);
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Obtiene todos los usuarios de la base de datos y los coloca en una lista.
     * @method
     * @param {Object} { currentPage: 0, itemsPerPage: 15, search: ""}
     */
    async getAllUserDashboardAbasto(pages) {
      this.loading = UtilFront.getLoadding();
      await ConfigurationRequest.getAllUserSupplied(pages)
        .then((res) => {
          let { data } = res;
          if (data) {
            this.listUsersAbasto = data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    /**
     * Obtiene todos los usuarios del tablero de estadística desde el servidor y luego
     * asigna los datos a la variable listUsersStatistic.
     * @method
     * @param {Object} { currentPage: 0, itemsPerPage: 15, search: ""}
     */
    async getAllUserDashboardStatistics(pages) {
      this.loading = UtilFront.getLoadding();
      await ConfigurationRequest.getAllUserDashboardStatistics(pages)
        .then((res) => {
          let { data } = res;
          if (data) {
            this.listUsersStatistic = data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.alert("error", error.message);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
  },
  created() {
    if (
      this.withPermissionName(this.permissions.CONFIGURATION_DASHBOARD_ABASTO)
    )
      this.typeDashboard = 1;
    else this.typeDashboard = 2;
  },
  watch: {
    typeDashboard() {
      this.pagesAtributes = {
        currentPage: 0,
        itemsPerPage: 15,
        search: "",
      };
    },
  },
  components: {
    TableStatisticConfigComponents,
    TableAbastoConfigComponents,
    FormAbastoConfigComponents,
    AlertMessageComponent,
  },
};
</script>
