<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="modelTable.content"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        'items-per-page-all-text': 'Todo',
        'items-per-page-options': [15, 30, 50, 100],
      }"
      loading-text="Cargando..."
      no-data-text="Sin resultados disponibles"
      no-results-text="Sin resultados coincidentes con la búsqueda"
      dense
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right">
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              label="Buscar..."
              :disabled="loading_search"
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>

      <template v-slot:[`item.userLevel`]="{ item }">
        {{
          item.levelDashboard == 1
            ? "ABASTO"
            : item.levelDashboard == 2
            ? "JURISDICCIÓN"
            : item.levelDashboard == 3
            ? "SUCURSAL"
            : "Sin Asignar"
        }}
      </template>

      <template v-slot:[`item.jurisdictionName`]="{ item }">
        {{
          item.jurisdictionName && item.levelDashboard == 2
            ? item.jurisdictionName
            : "-"
        }}
      </template>

      <template v-slot:[`item.distributionBoardName`]="{ item }">
        {{ item.distributionBoardId ? item.distributionBoardName : "-" }}
      </template>
      <template v-slot:[`item.availableDashboard`]="{ item }">
        <v-flex md12>
          <v-layout column align-center>
            <v-switch
              v-if="isStatus && item.levelDashboard"
              color="success"
              class="hand text-center"
              hide-details
              @click="confirmChangeStatus(item)"
              dense
              inset
              v-model="item.availableDashboard"
            >
            </v-switch>
            <span
              class="mr-5"
              v-if="
                !item.availableDashboard && isStatus && !item.levelDashboard
              "
            >
              -
            </span>
          </v-layout>
          <div v-if="!isStatus">
            {{
              !item.levelDashboard
                ? "-"
                : item.availableDashboard
                ? "Activo"
                : "Inactivo"
            }}
          </div>
        </v-flex>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip
          v-if="isUpdate && item.levelDashboard && item.availableDashboard"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              small
              class="mr-2 hand"
              @click="updateItem(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-edit"></i>
            </span>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip v-if="isAssign && !item.levelDashboard" top>
          <template v-slot:activator="{ on, attrs }">
            <span
              small
              class="mr-2 hand"
              @click="toAssingDashboard(item)"
              v-bind="attrs"
              v-on="on"
            >
              <i class="feather icon-arrow-right-circle"></i>
            </span>
          </template>
          <span>Asignar Nivel</span>
        </v-tooltip>
        <span class="mr-5" v-if="!isAssign && !isUpdate"> - </span>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>
<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  props: {
    headers: {},
    list: [],
    isUpdate: false,
    isButton: false,
    isAssign: false,
    isStatus: false,
    toAssingDashboard: Function,
    updateItem: Function,
    confirmChangeStatus: Function,
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      page: 1,
      options: {
        sortDesc: [true],
      },
      loading: false,
      loading_search: false,
    };
  },
  methods: {
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      this.pagesAtributes.currentPage = this.options.page - 1;
      this.pagesAtributes.itemsPerPage = this.options.itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
    reloadPages() {
      this.page = 1;
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
  components: {
    ButtonPrintComponent,
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .v-text-field__suffix {
    font-size: 13px !important;
  }
}
</style>
