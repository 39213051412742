<template>
  <div class="p-2">
    <v-data-table
      :headers="headers"
      :items="modelTable.content"
      :search="search"
      height="auto"
      fixed-header
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
        'items-per-page-all-text': 'Todo',
        'items-per-page-options': [15, 30, 50, 100],
      }"
      loading-text="Cargando..."
      no-data-text="Sin resultados disponibles"
      no-results-text="Sin resultados coincidentes con la búsqueda"
      dense
      :server-items-length="modelTable.totalElements"
      :options.sync="options"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:top>
        <div class="row justify-end">
          <div class="col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right">
            <v-text-field
              v-model="search"
              v-on:keyup.enter="searchbar"
              :suffix="search == '' ? '' : 'enter'"
              class="br"
              :disabled="loading_search"
              label="Buscar..."
              ><template v-slot:append>
                <span class="pt-2">
                  <i
                    class="feather icon-corner-down-left"
                    v-show="search != ''"
                  >
                  </i>
                </span> </template
            ></v-text-field>
          </div>
        </div>
      </template>
      <template v-slot:[`item.availableDashboard`]="{ item }">
        <v-flex md12>
          <v-layout column align-center v-if="isStatus">
            <v-switch
              color="success"
              @click="confirmchangeStatus(item)"
              class="hand text-center"
              hide-details
              dense
              inset
              v-model="item.availableDashboard"
            >
            </v-switch>
          </v-layout>
          <div v-else>
            {{ item.availableDashboard ? "Activo" : "Inactivo" }}
          </div>
        </v-flex>
      </template>
    </v-data-table>
    <ButtonPrintComponent />
  </div>
</template>

<script>
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";

export default {
  props: {
    headers: {},
    isButton: false,
    isStatus: false,
    confirmchangeStatus: Function,
    /* Pagination */
    methodPage: Function,
    pagesAtributes: Object,
    modelTable: Object,
  },
  data() {
    return {
      search: "",
      page: 1,
      options: {
        sortDesc: [true],
      },
      loading: false,
      loading_search: false,
    };
  },
  methods: {
    async pagination() {
      this.pagesAtributes.sortBy =
        this.options.sortBy.length > 0 ? this.options.sortBy[0] : "";
      this.pagesAtributes.sortDesc = this.options.sortDesc[0];
      const { page, itemsPerPage } = this.options;

      this.pagesAtributes.currentPage = page - 1;
      this.pagesAtributes.itemsPerPage = itemsPerPage;
      await this.methodPage(this.pagesAtributes);
    },
    async searchbar() {
      try {
        this.loading_search = true;
        this.pagesAtributes.search = this.search;
        await this.performSearch(this.pagesAtributes);
        this.page = 1;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading_search = false;
      }
    },
    async performSearch(pagesAtributes) {
      await this.methodPage(pagesAtributes);
    },
  },
  watch: {
    search() {
      if (this.search == "") {
        this.searchbar();
      }
    },
    options: {
      handler() {
        this.pagination();
      },
      deep: true,
    },
  },
  components: {
    ButtonPrintComponent,
  },
};
</script>
