var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.modelTable.content,"search":_vm.search,"height":"auto","fixed-header":"","footer-props":{
      'items-per-page-text': 'Elementos por página',
      'items-per-page-all-text': 'Todo',
      'items-per-page-options': [15, 30, 50, 100],
    },"loading-text":"Cargando...","no-data-text":"Sin resultados disponibles","no-results-text":"Sin resultados coincidentes con la búsqueda","dense":"","server-items-length":_vm.modelTable.totalElements,"options":_vm.options,"page":_vm.page,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"row justify-end"},[_c('div',{staticClass:"col-sm-12 col-md-6 col-lg-5 col-xl-5 pb-1 text-right"},[_c('v-text-field',{staticClass:"br",attrs:{"suffix":_vm.search == '' ? '' : 'enter',"label":"Buscar...","disabled":_vm.loading_search},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchbar.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pt-2"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.search != ''),expression:"search != ''"}],staticClass:"feather icon-corner-down-left"})])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]},proxy:true},{key:"item.userLevel",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.levelDashboard == 1 ? "ABASTO" : item.levelDashboard == 2 ? "JURISDICCIÓN" : item.levelDashboard == 3 ? "SUCURSAL" : "Sin Asignar")+" ")]}},{key:"item.jurisdictionName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.jurisdictionName && item.levelDashboard == 2 ? item.jurisdictionName : "-")+" ")]}},{key:"item.distributionBoardName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.distributionBoardId ? item.distributionBoardName : "-")+" ")]}},{key:"item.availableDashboard",fn:function(ref){
    var item = ref.item;
return [_c('v-flex',{attrs:{"md12":""}},[_c('v-layout',{attrs:{"column":"","align-center":""}},[(_vm.isStatus && item.levelDashboard)?_c('v-switch',{staticClass:"hand text-center",attrs:{"color":"success","hide-details":"","dense":"","inset":""},on:{"click":function($event){return _vm.confirmChangeStatus(item)}},model:{value:(item.availableDashboard),callback:function ($$v) {_vm.$set(item, "availableDashboard", $$v)},expression:"item.availableDashboard"}}):_vm._e(),(
              !item.availableDashboard && _vm.isStatus && !item.levelDashboard
            )?_c('span',{staticClass:"mr-5"},[_vm._v(" - ")]):_vm._e()],1),(!_vm.isStatus)?_c('div',[_vm._v(" "+_vm._s(!item.levelDashboard ? "-" : item.availableDashboard ? "Activo" : "Inactivo")+" ")]):_vm._e()],1)]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [(_vm.isUpdate && item.levelDashboard && item.availableDashboard)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-2 hand",attrs:{"small":""},on:{"click":function($event){return _vm.updateItem(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-edit"})])]}}],null,true)},[_c('span',[_vm._v("Editar")])]):_vm._e(),(_vm.isAssign && !item.levelDashboard)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"mr-2 hand",attrs:{"small":""},on:{"click":function($event){return _vm.toAssingDashboard(item)}}},'span',attrs,false),on),[_c('i',{staticClass:"feather icon-arrow-right-circle"})])]}}],null,true)},[_c('span',[_vm._v("Asignar Nivel")])]):_vm._e(),(!_vm.isAssign && !_vm.isUpdate)?_c('span',{staticClass:"mr-5"},[_vm._v(" - ")]):_vm._e()]}}],null,true)}),_c('ButtonPrintComponent')],1)}
var staticRenderFns = []

export { render, staticRenderFns }