var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.toAssingDashboard.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-0"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",attrs:{"dense":""},domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" Información de asignación ")],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"level_dashboard","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.listNivelDashboard,"error-messages":errors,"required":"","label":_vm.$t('label.level_dashboard'),"data-vv-name":"select"},model:{value:(_vm.modelUserAbasto.levelDashboard),callback:function ($$v) {_vm.$set(_vm.modelUserAbasto, "levelDashboard", $$v)},expression:"modelUserAbasto.levelDashboard"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"dasboard_distribution_board_main","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typeDashboard,"error-messages":errors,"required":"","label":_vm.$t('label.dasboard_distribution_board_main'),"data-vv-name":"select"},model:{value:(_vm.modelUserAbasto.distributionBoardId),callback:function ($$v) {_vm.$set(_vm.modelUserAbasto, "distributionBoardId", $$v)},expression:"modelUserAbasto.distributionBoardId"}})]}}],null,true)})],1),(_vm.modelUserAbasto.levelDashboard == 2)?_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"dasboard_distribution_board_main","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.jurisdictions,"error-messages":errors,"required":"","label":_vm.$t('label.jurisdiction'),"data-vv-name":"select"},model:{value:(_vm.modelUserAbasto.jurisdictionId),callback:function ($$v) {_vm.$set(_vm.modelUserAbasto, "jurisdictionId", $$v)},expression:"modelUserAbasto.jurisdictionId"}})]}}],null,true)})],1):_vm._e(),(_vm.modelUserAbasto.levelDashboard == 3)?_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"pb-5"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",attrs:{"dense":""},domProps:{"textContent":_vm._s('mdi-numeric-2-box-outline')}}),_vm._v(" Asignación de sucursales ")],1)]),_c('BranchOfficeCommon',{ref:"get_listBranchOfficeUser",attrs:{"userListBranchOffice":_vm.modelUserAbasto.userListBranchOfficeIds}})],1):_vm._e()]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.modelUserAbasto.idDashboard ? "Modificar" : "Asignar")+" ")])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }